.hp-100 {
    height: 100%;
}
.min-hp-100 {
    min-height: 100%;
}
.File-viewer {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat!important;
    background-position: center!important;
    background-size: cover!important;
    border-radius: $border-radius-base;
    position: relative;

    &.file {
        background: url("../images/file.svg");
        background-size: contain!important;
    }

    .buttons-control {
        position: absolute;
        top: 5px;
        right: 5px;

        button {
            margin-left: 5px;
            width: 40px;
            height: 40px;
            line-height: 40px;
            border-radius: 50%;
            border: 1px solid transparent;
            box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
            font-size: 18px;
            cursor: pointer;
            outline: 0;

            &.delete {
                background: $error-color;
                color: #ffffff;
                transition: .2s;

                &:hover {
                    background: #ffffff;
                    border-color: $error-color;
                    transition: .2s;
                    color: $error-color;
                }
            }

            &.download {
                background: $primary-color;
                color: #ffffff;
                transition: .2s;

                &:hover {
                    background: #ffffff;
                    border-color: $primary-color;
                    transition: .2s;
                    color: $primary-color;
                }
            }

            &.edit {
                background: $primary-color;
                color: #ffffff;
                transition: .2s;

                &:hover {
                    background: #ffffff;
                    border-color: $primary-color;
                    transition: .2s;
                    color: $primary-color;
                }
            }
        }
    }
}
/*
    Files
*/
.Files-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: auto -5px;
    padding: 0;
    overflow-y: auto;
}
.Files-item {
    display: flex;
    padding: 5px;
    position: relative;
    max-width: 20%;
    flex-basis: 100%;
    flex-direction: column;
    background: #ffffff;

    &.Files-item-single {
        max-width: 212px;
        min-width: 212px;
    }
}
.Files-inner-item {
    height: 212px;
    display: flex;
    max-width: 100%;
    max-height: 100%;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    background: #F3F3F3;
    border-radius: $border-radius-base;
}
.Files-SortableList {
    position: relative;
    z-index: 0;
    background-color: #F3F3F3;
    border: 1px solid #EFEFEF;
    outline: none;
    width: 400px;
    height: auto;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border: 1px solid #E0E0E0;
    list-style: none;
    padding: 0;
}
.Files-SortableItem {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 60px;
    padding: 0 10px;
    background-color: #FFF;
    border-bottom: 1px solid #EFEFEF;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #333;
    font-weight: 500;
}
.Files-SortableHelper {
    box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 6px, rgba(0, 0, 0, 0.075) 0px 1px 4px;
    background-color: rgba(255, 255, 255, 0.8);
    cursor: row-resize;
}

.EventGrid {
    margin: 0;
    padding: 0;

    .EventGrid-item {
        list-style-type: none;
        height: $event-grid-item-height;
        overflow: hidden;
        border-left: 3px solid $border-color-base;
        position: relative;

        .EventGrid-clickable {
            cursor: pointer;
        }

        .EventGrid-column {
            display: flex;
            height: $event-grid-item-height;
            padding: 0 5px;
            float: left;
            background: #ffffff;

            span {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .EventGrid-time {
            width: 50px;
        }

        .EventGrid-duration {
            width: 40px;

            span {
                text-align: center;
                width: 100%;
            }
        }

        .EventGrid-control {
            padding-right: 0;
            width: 100px;

            button {
                height: $event-grid-item-height;
                background-color: $success-color;
                color: #ffffff;
                width: 100%;
                font-size: 20px;
                border-radius: 0;
                border-width: 0;
                outline: 0;
                cursor: pointer;
                transition: .2s;
            }

            .EventGrid-control-pause,
            .EventGrid-control-stop {
                width: 50%;
            }

            .EventGrid-control-pause {
                background-color: $warning-color;
            }

            .EventGrid-control-stop {
                background-color: $error-color;
            }

            button:hover {
                opacity: .5;
                transition: .2s;
            }
        }

        .EventGrid-projectName {
            width: 100px;

            span {
                width: 100%;
            }
        }

        .EventGrid-description {
            width: calc(100% - 330px);

            span {
                line-height: 1;
            }
        }

        &.EventGrid-item-diff {
            margin-top: 0;
            border-color: $success-color;
            height: 25px;

            .EventGrid-column-free {
                width: 100%;
                padding: 0;
                height: 25px;

                span {
                    width: 100%;
                    text-align: center;
                    background: rgba($success-color, .1);
                }
            }

            .EventGrid-column-withoutRest {
                width: 100%;
                padding: 0;
                height: 25px;

                span {
                    width: 100%;
                    text-align: center;
                    background: #f0f2f5;
                }
            }
        }
    }
}
.EventGridFilter {
    padding: 0 0 0 10px;

    .calendar {
        border: 1px solid $border-color-base;
        border-radius: $border-radius-base;
        background: #ffffff;
    }
}

.TitleBar-container {
    margin-bottom: 10px;
    box-shadow: rgba(0, 0, 0, 0.03) 0px 1px 6px, rgba(0, 0, 0, 0.03) 0px 1px 4px;

    .style_ControlsWrapper__1Sa4Y {
        display: none!important;
    }

    button {
        cursor: pointer;
    }

    .TitleBar-logout {
        border: 0;
        font-size: 17px;
        margin: 0 5px;
        background: transparent;
        cursor: pointer;
        outline: 0;
    }


    div#logo {
        background-image: url("../images/logoSmall.png");
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .grid-box {
        display: flex;
        width: 100%;

        .left-grid,
        .right-grid {
            display: flex;
        }

        .left-grid {
            width: calc(100% - 100px);

            .style_MenuButtonContainer {
                min-width: 0;
                flex-shrink: 0;
                height: 100%;
                box-sizing: content-box;
                outline: none;
                position: relative;
                -webkit-app-region: no-drag;

                .style_MenuButtonWrapper {
                    width: 100%;
                    height: 100%;

                    button {
                        -webkit-appearance: none;
                        border: none;
                        box-shadow: none;
                        background: transparent;
                        border-radius: 0;
                        text-align: left;
                        margin: 0;
                        padding: 0;
                        height: 100%;
                        width: 100%;
                        font-size: 12px;
                        padding-left: 10px;
                        padding-right: 10px;
                        box-sizing: border-box;
                        outline: none;
                    }
                }
            }
        }

        .right-grid {
            width: 100px;
            justify-content: flex-end;
        }
    }

    .style_Title__3pWgw {
        display: none;
    }
}
.grid-ReactCrop {
    position: relative;
}
.Tag-Editor {
    .Tag-Editor-toolbar {
        border: 1px solid $border-color-base;
        margin-bottom: 0;
        border-radius: 0;
        border-top-left-radius: $border-radius-base;
        border-top-right-radius: $border-radius-base;

        .rdw-option-wrapper {
            border: 1px solid $border-color-base;
            padding: 0;
            min-width: 30px;
            height: 30px;
            border-radius: $border-radius-base;
            margin: 0 5px;
            transition: .2s;

            &:hover {
                box-shadow: none;
                transition: .2s;
                border-color: rgba($primary-color, 1);
            }

            &.rdw-option-active {
                box-shadow: none;
                background: rgba($primary-color, .5);
                border-color: rgba($primary-color, 1);
            }
        }

        .rdw-dropdown-wrapper {
            border: 1px solid $border-color-base;

            &:hover {
                box-shadow: none;
            }

            .rdw-dropdown-selectedtext {
                color: $text-color;

                span {
                    padding: 0 20px 0 5px;
                }
            }

            .rdw-dropdown-optionwrapper {
                width: 100%;
                border: 1px solid $border-color-base;

                &:hover {
                    box-shadow: none;
                }
            }

            &.rdw-block-dropdown {
                width: 150px;
            }
        }
    }

    .Tag-Editor-editor {
        border-left: 1px solid $border-color-base;
        border-right: 1px solid $border-color-base;
        border-bottom: 1px solid $border-color-base;
        border-bottom-left-radius: $border-radius-base;
        border-bottom-right-radius: $border-radius-base;
        background: #ffffff;

        .DraftEditor-root {
            padding: 5px 10px;
        }
    }
}
.style_MenuBar__1Rm7F {
    flex-shrink: 0!important;

    .style_MenuButtonContainer__3d9pg:last-child {
        display: none;
    }
}
.ant-form-item-label {
    line-height: 1;
}
.order-box {
    background: #ffffff;
    border-radius: $border-radius-base;
    // box-shadow: $box-shadow-base;
}
.login-form {
    footer {
        text-align: center;
    }
}
.justify-content-center {
    justify-content: center;
}