.LogisticsEditor {
    #filter {
        height: 70px;
        overflow: hidden;
    }

    div[class^="root-"] {
        width: 100%;
        height: 100%;
        background-color: #fff;
        padding: 0;
    }

    .renderDetailsHeader {
        padding-top: 0;
    }

    .contextMenu-content {
        position: relative;
    }

    .order-table-map {
        display: flex;
        flex-direction: row;

        .react-resizable {
            position: relative;

            .react-resizable-handle {
                width: 10px;
                height: 100%;

                position: absolute;
                background-color: #000;
                right: -5px;
                top: 0;
                cursor: ew-resize;
                z-index: 999;
            }
        }
    }

    .routes {
        padding-top: 10px;
        .react-resizable {
            position: relative;

            .react-resizable-handle {
                width: 100%;
                height: 10px;

                position: absolute;
                background-color: #000;
                right: 0;
                top: -10px;
                cursor: ns-resize;
                z-index: 999;
            }
        }
    }

    .order-table-map,
    .routes {
        .rs-table-body-row-wrapper {
            .rs-table-cell-content {
                padding: 0;
            }
        }

        .customCell {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 13px 10px;
            width: 100%;
            height: 100%;
        }

        .checkbox {
            .rs-radio-wrapper,
            .rs-checkbox-wrapper {
                top: 15px;
                left: 20px;
            }
        }
    }

    .separator {
        width: 100%;
        height: 100%;
        background-color: transparent;
    }

    .total-sum-info {
        width: 100%;
        td {
            width: 50%;

            &:last-child {
                text-align: right;
                font-weight: bold;
            }
        }
    }
}
.customDropdown {
    .rs-btn {
        display: none;
    }
}
.DetailsList {
    position: relative;
    .renderDetailsHeader {
        .ms-DetailsHeader-checkTooltip {
            .ms-DetailsRow-check {
                .ms-Check {
                    display: none;
                }
            }
        }
    }
}