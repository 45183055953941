.h-100-vh {
    height: 100vh!important;
}
.mh-100-vh {
    min-height: 100vh!important;
}
.mh-500 {
    min-height: 500px;
}
.ant-layout-header {
    padding: 0 25px!important;
    background: transparent!important;
}
.ant-layout-sider {
    box-shadow: 5px 0 10px rgba(0,0,0,.02)!important;
    background: #ffffff!important;
}
.trigger {
    font-size: 18px;
}
.content-dashboard {
    padding: 0 10px;
}
.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
    border-right: 0!important;
}
.ant-menu {
    background: transparent!important;
}
.ant-tabs-nav .ant-tabs-tab-active {
    font-weight: 400!important;
}
.dib {
    display: inline-block!important;
}
.flex{
    display: flex;
}
.ant-form-item-control{
    margin-right: .2rem;
}
.text-center {
    text-align: center;
}
#logo-dashboard {
    background: #f5f6f7;
    padding: 20px 0;
    display: inline-block;
    width: 100%;
}
#logo-dashboard>div {
    display: block;
    width: 64px;
    height: 64px;
    margin: 0 auto;
    background: url("../../images/logo.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.none {
    display: none!important;
}
.mw-100-p {
    min-width: 100%!important;
}
.w-100-p {
    width: 100%!important;
}

#nprogress .bar {
    background: #1890ff !important;
}

#nprogress .peg {
    box-shadow: 0 0 10px #1890ff, 0 0 5px #1890ff !important;
}

#nprogress .spinner-icon {
    border-top-color: #1890ff !important;
    border-left-color: #1890ff !important;
}
.block {
    margin-bottom: 24px;
    background-color: #fff;
    box-shadow: 0 1px rgba(0,0,0,.03);
}
.block-header-default {
    background-color: #fbfbfc;
}
.block-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 14px 18px;
    transition: opacity .2s ease-out;
}
.block-content {
    transition: opacity .2s ease-out;
    margin: 0 auto;
    padding: 18px 18px 1px;
    width: 100%;
    overflow-x: visible;
}
.block-title {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 28px;
    margin: 0;
    font-size: 1.142857rem;
    font-weight: 400;
    line-height: 28px;
}
.block-options {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-left: 10px;
    min-height: 28px;
}
.btn-block-option {
    display: inline-block;
    padding: 6px 5px;
    line-height: 1.2;
    color: #6c757d;
    background: 0 0;
    border: none;
    cursor: pointer;
    margin: 0 10px;
    border-bottom: 0;
}
.filter-collapse {
    background-color: #fbfbfc!important;
    border: 1px solid #e8e8e8!important;
}
.filter-collapse.ant-collapse > .ant-collapse-item:last-child,
.filter-collapse.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 0!important;
    border-bottom: 0!important;
}
.loading-jsx {
    text-align: center;
    background: #fbfbfc;
    padding: 30px 50px;
    margin: 20px 0;
}
.popover {
    position: absolute!important;
    z-index: 999!important;
}

.Portal-contextmenu {
    position: absolute!important;
    z-index: 999!important;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: block;
    background: rgba(000, 000, 000, .1);

    .Portal-contextmenu-port {
        position: absolute!important;
        z-index: 9999!important;
        display: inline-block;
        background: #ffffff;
        border: #fbfbfc;
        padding: 5px;
        box-shadow: rgba(0, 0, 0, 0.03) 0px 1px 6px, rgba(0, 0, 0, 0.03) 0px 1px 4px;
        border-radius: 5px;

        ul {
            padding: 0;
            margin: 0;

            li {
                list-style-type: none;
            }
        }
    }
}
.popover-cover {
    top: 0px!important;
    right: 0px!important;
    bottom: 0px!important;
    left: 0px!important;
}
.relative {
    position: relative!important;
}
.preview-color {
    width: 70%;
    height: 70%;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 15%;
    margin-left: 15%;
    border-radius: 3px;
}
.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}
.main-tabls {
    box-shadow: 0 1px rgba(0,0,0,.03);
    width: 100%;
    height: 100%;

}
.ant-tabs .ant-tabs-top-content, .ant-tabs .ant-tabs-bottom-content {
    width: 100%;
    height: 100%;
}
.main-tabls>.ant-tabs-bar {
    margin: 0;
}
.main-tabls>.ant-tabs-bar {
    background-color: #f5f6f7;
}
.main-tabls>.ant-tabs-bar>.ant-tabs-nav-container .ant-tabs-tab-active {
    color: #575757;
    background-color: #fff;
    border-color: transparent;
}
.main-tab-content {
    background: #ffffff;
    // padding: 15px 0 15px 15px;
    padding: 15px;
    position: relative;
}
.main-tab-content .loading {
    text-align: center;
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    padding-top: 5%;
}
.ant-tabs-nav .ant-tabs-tab {
    margin: 0;
}
.container-button-close {
    margin: 0 10px;
}
.hide-label label[for] {
    display: none;
}
.edittable-ant-form-item .ant-form-item .ant-form-item-label {
    display: none;
}
.edittable-ant-form-item .ant-form-item {
    margin: 0!important;
}
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}
.table-responsive tr td {
    width: 25%;
}
#nprogress .bar {
    background: #1890ff !important;
}
#nprogress .peg {
    box-shadow: 0 0 10px #1890ff, 0 0 5px #1890ff !important;
}
#nprogress .spinner-icon {
    border-top-color: #1890ff !important;
    border-left-color: #1890ff !important;
}
.rbc-allday-cell {
    display: none;
}
.rbc-time-content {
    border-top: 1px solid #DDD;
}
.rbc-header {
    border-bottom: 0;
}
.rbc-day-slot .rbc-events-container {
    margin-left: 10px;
    margin-right: 10px;
    border-left: 0;
}
.rbc-current-time-indicator {
    height: 3px;
    opacity: .5;
}
.rbc-time-view {
    border-bottom: 0;
}

.hide {
    display: none!important;
}
.text-center {
    text-align: center!important;
}
.text-right {
    text-align: right!important;
}
.lh-32 {
    line-height: 32px;
}
.w-100-p {
    width: 100%!important;
}
.w-auto {
    width: auto!important;
}
.relative {
    position: relative;
}
.answer-kkt-card {
    width: 100%;
    height: 500px;
    overflow-x: hidden;
    overflow-y: auto;
}
/* Стилизуем таблицу TableDataSheet */
.data-grid-container .data-grid .cell > input {
    width: 100%;
    height: 100%;
    border: none;
}
.data-grid-container .data-grid .cell {
    padding: 0 5px;
}
.data-grid-container .data-grid .cell {
    min-width: 100px;
    height: 25px;
    vertical-align: middle;
}
.custom-react-select .css-yk16xz-control {
    border: none!important;
    border-radius: 0!important;
    min-height: 25px!important;
    height: 25px!important;
}
.custom-react-select .css-1g48xl4-IndicatorsContainer {
    display: none;
}
.custom-react-select .css-1hwfws3 {
    padding: 0!important;
    height: 25px!important;
    line-height: 25px!important;
}
.scroll-area {
    width: 100%;
    /* height: 500px; */
    overflow: auto;
    border-right: 1px solid #DDD;
    border-left: 1px solid #DDD;
}
.data-grid-container .data-grid tr .cell:first-child {
    border-left: 0!important;
}
.data-grid-container .data-grid tr .cell:last-child {
    border-right: 0!important;
}
.leaflet-container {
    height: 500px;
    width: 100%;
    border: 1px solid transparent;
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    margin-bottom: 25px;
}

.area-map .leaflet-draw-draw-polygon,
.area-map .leaflet-draw-draw-circle,
.area-map .leaflet-draw-draw-marker,
.area-map .leaflet-draw-draw-circlemarker {
    display: none!important;
}
.upload-content .ant-card-actions > li {
    margin: 5px 0;
}
.upload-content .ant-upload.ant-upload-select {
    width: 100%;
}
.upload-content .show-file-upload .anticon svg {
    font-size: 100px;
}
.upload-content .show-file-upload {
    max-width: 100px;
    max-height: 100px;
    display: block;
    margin: 0 auto;
    text-align: center;
}
.upload-content .show-image-upload img {
    display: block;
    margin: 0 auto;
    width: 100%;
}
.data-grid-container .data-grid .cell.read-only {
    background: #ffffff;
}
.xcel-table-cell-header {
    position: relative;
    padding: 0 20px;
    display: inline-block;
    width: 100%;
    cursor: pointer;
}
.xcel-table-cell-header::after {
    content: "";
    background: url("../../images/arrowbottom.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 15px;
    height: 15px;
    position: absolute;
    top: calc((100% - 15px)/2);
    right: 0;
    transition: .2s;
}
.xcel-table-cell-header.sort-type-asc::after {
    transform: rotate(0deg);
}
.xcel-table-cell-header.sort-type-desc::after {
    transform: rotate(180deg);
}
.grid-progress-car {
    border: 1px solid #e8e8e8;
    padding: 0 10px;
}
.second-toolbar #logo {
    display: none;
}
.second-toolbar {
    height: 40px;
}
.ant-fullcalendar-header .ant-radio-group {
    display: none;
}
.events {
  list-style: none;
  margin: 0;
  padding: 0;
}
.events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}
/* virtualized */
.ReactVirtualized__Grid {
    outline: 0;
}
.list-virtualized {

}
.list-virtualized .list-virtualized-item {
    border-bottom: 1px solid #e8e8e8;
    background-color: rgba(232, 232, 232, 0);
    padding: 5px;
    cursor: pointer;
    overflow: hidden;
    transition: .2s;
}
.list-virtualized .list-virtualized-item.active {
    background-color: rgba(232, 232, 232, .3);
    transition: .2s;
}
.list-virtualized .list-virtualized-item .caption {
    font-weight: 500;
}
.ant-select.ant-fullcalendar-month-select.ant-select-sm,
.ant-select.ant-fullcalendar-year-select.ant-select-sm {
    width: auto;
}
.tags-list {
    padding: 0 0 25px 0;
}
.leaflet-control-geosearch form {
    left: 35px;
}
.leaflet-control-geosearch form input {
    height: 30px;
}
/* Таблица */
.journal-records {
    background: #ffffff;
    padding: 24px 20px 24px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,.05);
    -moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,.05);
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,.05);
}

.ReactVirtualized__Grid,
.ReactVirtualized__Collection {
    outline: 0;
}
.content-table {
    border: 1px solid #e0e0e0;
    background: #ffffff;
    >div {
        display: flex;
    }
}
.GridContainer {
    display: inline-block;
}
.GridRow {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: relative;
    z-index: 9;
}
.GridRow.static .ReactVirtualized__Grid {
    overflow: hidden!important;
}
.GridColumn {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 .5em;

    border-right: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;

    &.border-bottom-0 {
        border-bottom: 0;
    }

    .child {
        .commnent {
            &::after {
                content: "";
                // width: 10px;
                // height: 10px;
                display: block;
                position: absolute;
                right: 0;
                top: 0;
                // background: red;
                border: 5px solid transparent;
                border-top: 5px solid green;
                border-right: 5px solid green;
            }
        }
        .nowrap-ellipsis {
            cursor: pointer;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:hover {
                background: rgba(#e0e0e0, .5);
            }
        }
    }
}
.read-only {
    background: #f5f5f5;
}
.GridRow.static .GridColumn {
    background: #f5f5f5;
}
/*
    GridSortable
*/
.GridSortable-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: auto;
    padding: 10px;
    overflow-y: auto;
}
.GridSortable-item {
    display: flex;
    padding: 16px;
    position: relative;
    max-width: 20%;
    flex-basis: 100%;
    flex-direction: column;
    background: #ffffff;
}
.GridSortable-inner-item {
    height: 212px;
    display: flex;
    max-width: 100%;
    max-height: 100%;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    background: #F3F3F3;
}
.GridSortable-SortableList {
    position: relative;
    z-index: 0;
    background-color: #F3F3F3;
    border: 1px solid #EFEFEF;
    outline: none;
    width: 400px;
    height: auto;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border: 1px solid #E0E0E0;
    list-style: none;
    padding: 0;
}
.GridSortable-SortableItem {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 60px;
    padding: 0 10px;
    background-color: #FFF;
    border-bottom: 1px solid #EFEFEF;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #333;
    font-weight: 500;
}
.GridSortable-SortableHelper {
    box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 6px, rgba(0, 0, 0, 0.075) 0px 1px 4px;
    background-color: rgba(255, 255, 255, 0.8);
    cursor: row-resize;
}
.rbc-header {
    border-bottom: 0!important;
}
.row-html {
    img {
        width: 100%;
    }
}
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td {
    padding: 1px 5px;
}
.Drawer-title {
    display: flex;
}
.Drawer-button {
    width: 50px;
    align-items: center;
}
.Drawer-button,
.Drawer-text {
    display: flex;
    float: left;
}
.btn-column{
  display: flex;
}
