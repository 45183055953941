.bigtable-DetailsList {
    position: relative;
    height: 80vh;
    // border-top: 1px solid rgb(243, 242, 241);
    // border-left: 1px solid rgb(243, 242, 241);
    // border-bottom: 1px solid rgb(243, 242, 241);

    .cellname {
        flex: 0 1 auto;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
    }

    .ms-DetailsHeader {
        padding-top: 0;
    }

    .fixed-DetailsList {
        overflow: hidden;
    }

    .container {
        display: flex;
        position: relative;
        height: 100%;

        .list-container {
            display: inline-block;
            position: relative;
            height: 100%;
            width: 100%;
        }

        .list-row {
            display: flex;
            flex-wrap: wrap;
            position: relative;
            z-index: 9;
            height: 100%;
        }

        .ms-DetailsHeader {
            border-bottom: 0;

            .ms-DetailsHeader-cell:first-child {
                border-left: 1px solid rgb(243, 242, 241);   
            }
        }
        .ms-DetailsHeader-cell {
            border-bottom: 1px solid rgb(243, 242, 241);
            border-top:  1px solid rgb(243, 242, 241);
            &:hover {
                background-color: transparent;
            }
        }

        .ms-DetailsRow-fields {
            .ms-DetailsRow-cell:first-child {
                border-left: 1px solid rgb(243, 242, 241);
            }
        }

        .fixed-DetailsList,
        .ms-DetailsHeader-cell,
        .ms-DetailsRow-cell {
            border-right: 1px solid rgb(243, 242, 241);
        }
        .ms-DetailsRow {
            border-bottom: 1px solid rgb(243, 242, 241);

            &:hover {
                background: rgb(255, 255, 255);
                color: rgb(96, 94, 92);
                border-bottom: 1px solid rgb(243, 242, 241);
            }
        }

        .cell-div-jsx {
            display: flex;
            align-items: center;
            width: 100%;
            height: 45px;
            padding: 0 10px;
            position: relative;

            .commnent {
                position: absolute;
                top: 0;
                right: 0;
                width: 10px;
                height: 10px;
                
                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    right: 0;
                    top: 0;
                    border: 5px solid transparent;
                    border-top: 5px solid green;
                    border-right: 5px solid green;
                }
            }
        }

        .ms-DetailsRow-cell {
            display: flex;
            overflow: hidden;
            padding: 0;
        }
    }
    .span-input {
        font-size: 14px;
        font-weight: 400;
        box-shadow: none;
        margin: 0px;
        padding: 0px 8px;
        box-sizing: border-box;
        border-radius: 0px;
        border: none;
        background: none transparent;
        color: rgb(50, 49, 48);
        width: 100%;
        min-width: 0px;
        text-overflow: ellipsis;
        outline: 0px;
        border: 1px solid rgb(243, 242, 241);
        border-radius: 2px;
        height: 32px;
        line-height: 32px;
        transition: .2s;
        cursor: text;

        &:hover {
            transition: .2s;
            border-color: rgba(96, 94, 92, .5)
        }
    }

    .wrap-ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .db {
        width: 100%
    }
}
