$primary-color: #0078d4;
$link-color: #0078d4;
$success-color: #52c41a;
$warning-color: #faad14;
$error-color: #f5222d;
$font-size-base: 14px;
$heading-color: rgba(0, 0, 0, 0.85);
$text-color: rgba(0, 0, 0, 0.65);
$text-color-secondary: rgba(0, 0, 0, 0.45);
$disabled-color: rgba(0, 0, 0, 0.25);
// $border-radius-base: 4px;
$border-radius-base: 0;
$border-color-base: #d9d9d9;
$box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);
$event-grid-item-height: 45px;
$mailBox-height: 300px;
