.todo-section {
    .todoToolbar-item {
        min-height: 36px;
        position: relative;

        .todoToolbar-inner {
            cursor: pointer;
            box-sizing: border-box;
            min-height: 36px;
            height: 36px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            display: flex;
            align-items: center;
            padding: 0 12px;
            transition: .2s;

            &.active,
            &:hover {
                background: #f4f4f4;
                transition: .2s;
            }

            .todoToolbar-icon {
                transform: scaleX(1);

                i {
                    line-height: 24px;
                    font-size: 16px;
                    text-align: center;
                    color: #767678;
                }
            }

            .todoToolbar-title {
                margin: 0 8px;
                color: #34373d;
                font-size: 15px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-weight: 300;
            }

            .todoToolbar-count {
                margin-left: auto;
                padding: 0 4px;
                font-size: 15px;
            }
        }
    }

    .col {
        align-items: center;
        display: flex;
        margin-left: 0;

        &.todo-content {
            width: 100%;
            border-left: 1px solid #f4f4f4;
        }
    }

    .centerColumn {
        flex: 1 1 0px;
        display: flex;
        overflow: hidden;
        width: 100%;
        min-height: 600px;

        .main {
            flex: 1 1 0px;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            background-color: white;
            transition: background-color 0.3s;
        }

        .tasksToolbar {
            height: auto;
            display: block;
            padding: 0 16px;
            position: relative;
            align-items: center;
            flex-shrink: 0;

            .tasksToolbar-top {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                height: 48px;
            }

            .tasksToolbar-headline {
                margin-right: 20px;
                min-width: 100px;
                flex: 1 1 0px;
            }

            .tasksToolbar-titleContainer {
                align-items: center;
                display: flex;

                .tasksToolbar-titleItem {
                    min-width: 20px;
                }
            }

            .listTitle {
                color: #34373d;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 25px;
                padding: 6px 8px;
                margin: 0;
            }
        }

        .flex-container {
            position: relative;
            flex: 1 1 0px;
            display: flex;

            .flexBoxFix {
                flex-direction: column;
                display: flex;
                flex: 1;
                overflow: hidden;
            }
        }
    }

    .main-background {
        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1 1 0px;
        width: 100%;

        .backgroundLines {
            margin: 0 24px;
            flex: 1;
            background: linear-gradient(180deg, white, white 52px, #e5e5e5 52px, #e5e5e5 52px);
            background-size: 100% 53px;
            box-shadow: inset 0 1px 0 0 #e5e5e5;
        }
    }

    .addToTop {
        .baseAdd.addTask {
            box-shadow: 0 17px 0 -16px #e5e5e5;
            padding: 0 13px;
            margin: 0 8px;

            &.focused {
                box-shadow: 0 17px 0 -16px #767678;
            }
        }
        .baseAdd {
            overflow: hidden;
            position: relative;
            flex-shrink: 0;
            display: flex;
            align-items: center;

            .baseAdd-icon {
                width: 32px;
                height: 32px;
                padding: 4px;
                flex-shrink: 0;
                cursor: pointer;
                background: transparent;

                i {
                    text-align: center;
                    font-size: 16px;
                }
            }

            .baseAdd-input {
                padding: 16px 12px;
                border: none !important;
                background: none !important;
                box-shadow: none !important;
                border-radius: 0 !important;
                font-size: 14px;
                transition: none;
                color: #34373d;
                width: 100%;
                box-sizing: border-box;
            }

            .baseAdd-button {
                font-weight: 600;
                font-size: 13px;
            }
        }
    }

    .tasks {
        overflow: hidden;
        display: flex;
        z-index: 1;

        .chunkedComponentList {
            display: flex;
            width: 100%;

            &.sticky {
                flex: 1;
            }

            .chunkedScrollContainer {
                position: relative;
                flex: 1;
                overflow-y: auto;
                overflow-x: hidden;
                -webkit-overflow-scrolling: touch;
            }

            .componentList {
                margin: 0 9px;
            }
        }

        .taskItem {
            display: flex;
            flex-direction: column;

            .taskItem-body {
                min-height: 52px;
                display: flex;
                align-items: center;
                position: relative;
                word-wrap: break-word;
                word-break: break-word;
                padding: 0 12px;
                box-shadow: 0 17px 0 -16px #e5e5e5;
                flex-wrap: wrap;
            }

            &:hover .taskItem-body {
                background: #f4f6ff;
                box-shadow: inset 0 0 0 1px #f4f6ff;
            }
            &.active .taskItem-body {
                background: #ededed;
                box-shadow: inset 0 0 0 1px #ededed;
            }

            .importanceButton {
                margin-left: 4px;
                padding: 0px;
                cursor: pointer;
                height: 24px;
                width: 24px;
                color: #767678;
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            .checkBox {
                cursor: pointer;
                height: 32px;
                width: 32px;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;

                i {
                    font-size: 20px;
                    color: rgb(0, 120, 212);
                }

                .default {
                    display: block;
                }
                .hover {
                    display: none;
                }

                &:hover {
                    .default {
                        display: none;
                    }
                    .hover {
                        display: block;
                    }
                }
            }

            .taskItem-titleWrapper {
                background: none;
                width: 100%;
                height: 100%;
                border: none;
                box-shadow: none;
                text-align: left;
                font-size: 14px;
                color: #34373d;
                cursor: default;
                position: relative;
                flex: 1 1 0px;
                min-height: 52px;
                padding: 8px 12px;
                // line-height: 2rem;
                overflow: hidden;
                cursor: pointer;

                .taskItem-title {
                    color: #34373d;
                }

                .metaDataInfo {
                    display: flex;
                    align-items: center;
                    font-size: 12px;
                    line-height: 16px;
                    color: #34373d;
                    flex-wrap: wrap;

                    .metaDataInfo-group {
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                    }

                    .taskItemInfo-title {
                        max-width: 200px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    &:after {
                        content: '';
                        margin: 0 6px;
                        color: #767678;
                    }
                }
            }
        }
    }
}
